import styled from "styled-components"
import imgBackground from "../../../static/hero.jpg"
import heroAbout from "../../../static/hero_about.png"
import heroForm from "../../../static/hero_form.jpg"

const HeroStyled = styled.div`
  padding-top: 70px;
  padding-bottom: 120px;
  background: url(${imgBackground}), #000;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
  @media (max-width: 880px) {
    padding-top: 30px;
    padding-bottom: 50px;
    background-size: 650px;
  }
  h1 {
    font-size: 75px;
    line-height: 72px;
    font-weight: 900;
    color: #fff;
    max-width: 1020px;
    @media (max-width: 1080px) {
      font-size: 50px;
      line-height: 58px;
    }
    @media (max-width: 880px) {
      font-size: 41px;
      line-height: 52px;
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-color: ${props => props.colorHeading};
    }
    span {
      padding-bottom: 20px;
      display: inline-block;
      position: relative;
      overflow: hidden;
      @media (max-width: 880px) {
        padding-bottom:0;
        padding-right:0;
        display: inherit;
      }
      &:nth-child(1) {
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          transform: translate(-100vw);
          height: 7px;
          background: ${props => props.colorHeading};
          transition: 0.3s all;
          animation-name: load;
          animation-duration: 1s;
          animation-iteration-count: 1;
          animation-delay: 0.5s;
          transform-box: fill-box;
          animation-fill-mode: forwards;
          transform-origin: center;
          @media (max-width: 880px) {
            content: unset;
          }
        }
      }
      &:nth-child(2) {
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          transform: translate(-100vw);
          height: 7px;
          background: ${props => props.colorHeading};
          transition: 0.3s all;
          animation-name: load;
          animation-duration: 1s;
          animation-iteration-count: 1;
          animation-delay: 1s;
          transform-box: fill-box;
          animation-fill-mode: forwards;
          transform-origin: center;
          @media (max-width: 880px) {
            content: unset;
          }
        }
      }
      &:nth-child(3) {
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          transform: translate(-100vw);
          height: 7px;
          background: ${props => props.colorHeading};
          transition: 0.3s all;
          animation-name: load;
          animation-duration: 1s;
          animation-iteration-count: 1;
          animation-delay: 1.5s;
          transform-box: fill-box;
          animation-fill-mode: forwards;
          transform-origin: center;
          @media (max-width: 880px) {
            content: unset;
          }
        }
      }
      &:nth-child(4) {
        &:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          transform: translate(-100vw);
          height: 7px;
          background: ${props => props.colorHeading};
          transition: 0.3s all;
          animation-name: load;
          animation-duration: 1s;
          animation-iteration-count: 1;
          animation-delay: 2s;
          transform-box: fill-box;
          animation-fill-mode: forwards;
          transform-origin: center;
              }
              content: unset;
              }
        }
      }
    }
  
    @keyframes load {
      0% {
        transform: translate(-100vw);
      }
      100% {
        transform: translate(0);
      }
    }
  &.hero__text__wrapper {
    padding-top: 65px;
    margin-bottom: 340px;
    background: url(${heroAbout}), #000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right bottom;
    @media (max-width: 880px) {
      margin-bottom: 0;
    }
    .hero__text__image {
      margin: -20px auto -400px;
      z-index: 3;
      position: relative;
      text-align: center;
      @media (max-width: 880px) {
        margin:0 auto;
      }
      img{
        max-width: 90%;
        height: auto;
      }
    }
  }
  .hero__text {
    max-width: 570px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    h1 {
      font-size: 38px;
      line-height: 52px;
      font-weight: 900;
      color: #fff;
      text-align: center;
      display: flex;
      flex-direction: column;
      @media (max-width: 880px){
        text-decoration: none;
      }
      &:after {
        content: "";
        margin: 25px auto;
        display: inline-block;
        width: 70px;
        height: 1px;
        background: #4e4d4d;
      }
    }
    p {
      font-size: 18px;
      line-height: 26px;
      font-weight: 300;
      padding-bottom: 40px;
    }
  }
  &.hero__form {
    background: url(${heroForm}), #000;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right bottom;
    min-height: calc(100vh - 80px);
    @media (max-height: 880px) {
      min-height: 620px
    }
    &>*{
      padding-top: 9vh;
    }
    .container {
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 32px;
        bottom: -120px;
        width: 1px;
        height: 75%;
        background: #4e4d4d;
        @media (max-width: 880px) {
          content: unset;
        }
      }
    }
  }
  .hero__form__heading {
    font-size: 67px;
    line-height: 62px;
    @media (max-width: 1080px){
      font-size: 50px;
      line-height: 58px;
    }
    @media (max-width: 880px){
      font-size: 41px;
      line-height: 52px;
    }
    @media (max-width: 350px){
      font-size: 34px;
      line-height: 44px;
    }
  }
  .hero__from__container {
    padding-top: 60px;
    color: #fff;
    padding-left: 80px;
    max-width: 850px;
    @media (max-width: 880px){
      padding-left: 30px;
      padding-left: 0;
    }
    & > p {
      margin: 0;
      font-size: 21px;
      padding-bottom: 10px;
    }
    .newsletter__input {
      max-width: 450px;
      @media (max-width: 1080px){
          max-width: 390px;
    }
    @media (max-width: 880px){
          max-width:100%;
    }
    }
  }
  &.hero__case__wrapper {
    background: none;
    h1 {
      color: #000;
      font-size: 32px;
      line-height: 44px;
      max-width: 100%;
      @media (max-width: 880px){
        text-decoration: none;
      }
    }
  }
  .hero__case__logo,
  .hero__case__image {
    text-align: center;
    padding-bottom: 50px;
    img{
      max-width: 100%;
    }
  }
  .hero__case__image {
    display: flex;
    justify-content: center;
    & > div {
      position: relative;
      img {
        position: relative;
        z-index: 10;
      }
      &:after {
        content: "";
        position: absolute;
        top: 14px;
        right: -15px;
        left: 12px;
        bottom: -13px;
        border: 1px solid #707070;
      }
    }
  }
  .hero__case__container {
    max-width: 580px;
    margin: 0 auto;
    text-align: center;
  }
`

export default HeroStyled
