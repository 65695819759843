import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import myInitObject from "../ultis/variable"
import uuid from "react-uuid"
import Slider from "react-slick"

const LogoSider = () => {
  const data = useStaticQuery(graphql`
    query {
      swapi {
        logotypeSingleton {
          logotype {
            value
          }
        }
      }
    }
  `)
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          cssEase: "linear",
        },
      },
    ],
  }
  const { logotype } = data.swapi.logotypeSingleton
  return (
    <section className="rotator">
      <div className="container--small">
        <div className="rotator__wrapper">
          <Slider {...settings}>
            {logotype.map(logo => (
              <div key={uuid()} className="rotator__item">
                <img src={myInitObject.cockpitUrl + logo.value.path} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default LogoSider
