import React from "react"
import uuid from "react-uuid"
import Link from "gatsby-link"
import ButtonStyled from "./styles/ButtonStyles"
import HeroServicesStyled from "./styles/HeroServices"
import HeroStyled from "./styles/HeroStyles"
import TitleStyled from "./styles/titleStyles"
import NewsletterInput from "./newsletterInput"

function heroService(heading, colorHeading, services) {
  return (
    <HeroStyled colorHeading={colorHeading}>
      <section className="hero ">
        <div className="container">
          <h1
            dangerouslySetInnerHTML={{ __html: heading }}
            className="hero__heading"
          ></h1>
          <div className="hero__container">
            <div className="hero__services__wapper">
              <HeroServicesStyled>
                {services.map((item, i) => {
                  return (
                    <div
                      className={`hero__services__item hero__services__item--${i +
                        1}`}
                      key={uuid()}
                    >
                      <Link to={item.value.link}>
                        <span className="hero__services__item__number">
                          <span></span>0{i + 1}.
                        </span>
                        <p className="hero__services__item__heading">
                          {item.value.title}
                        </p>
                        <p className="hero__services__item__description">
                          {item.value.description}
                        </p>
                      </Link>
                      <ButtonStyled>
                        <Link to={item.value.link}>{item.value.textLink}</Link>
                      </ButtonStyled>
                    </div>
                  )
                })}
              </HeroServicesStyled>
            </div>
          </div>
        </div>
      </section>
    </HeroStyled>
  )
}

function heroForm(
  heading,
  headingLineColor,
  description,
  formPlaceholder,
  formButtonText,
  checkboxText,
  errorCheckbox,
  errorInput
) {
  return (
    <HeroStyled className="hero__form" colorHeading={headingLineColor}>
      <section className="hero hero__black">
        <div className="container">
          <h1
            dangerouslySetInnerHTML={{ __html: heading }}
            className="hero__heading hero__form__heading"
          ></h1>
          <div className="hero__from__container">
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
            <NewsletterInput
              textButton={formButtonText}
              color="#fff"
              placeholder={formPlaceholder}
              checkbox={checkboxText}
              errorCheckbox={errorCheckbox}
              errorInput={errorInput}
            />
          </div>
        </div>
      </section>
    </HeroStyled>
  )
}
function heroText(heading, text, img) {
  return (
    <HeroStyled className="hero__text__wrapper" colorHeading="#fff">
      <section className="hero hero__text hero__black">
        <div className="container">
          <TitleStyled
            textAlign="center"
            linePosition="center"
            color="#fff"
            lineColor="#fff"
          >
            <h1
              dangerouslySetInnerHTML={{ __html: heading }}
              className="hero__text__heading"
            ></h1>
          </TitleStyled>
          <div className="hero__text__container">
            <p dangerouslySetInnerHTML={{ __html: text }}></p>
          </div>
        </div>
      </section>
      <div className="hero__text__image">
        <img src={img} alt="" />
      </div>
    </HeroStyled>
  )
}
function heroCase(heading, text, img, logo) {
  return (
    <HeroStyled className="hero__case__wrapper" colorHeading="#000">
      <section className="hero hero__case">
        <div className="container">
          <TitleStyled
            textAlign="center"
            linePosition="center"
            color="#000"
            lineColor="#000"
          >
            <h1
              dangerouslySetInnerHTML={{ __html: heading }}
              className="hero__case__heading"
            ></h1>
          </TitleStyled>
          {logo ? (
            <div className="hero__case__logo">
              <img src={logo} alt="" />
            </div>
          ) : (
            ""
          )}
          <div className={`hero__case__image`}>
            <div>
              <img src={img} alt="" />
            </div>
          </div>
          {text ? (
            <div className="hero__case__container">
              <p dangerouslySetInnerHTML={{ __html: text }}></p>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    </HeroStyled>
  )
}

const Hero = ({
  heading,
  colorHeading,
  services,
  heroType,
  text,
  logo,
  img,
  headingLineColor,
  description,
  formPlaceholder,
  formButtonText,
  checkboxText,
  errorCheckbox,
  errorInput,
}) => {
  switch (heroType) {
    case "form":
      return heroForm(
        heading,
        headingLineColor,
        description,
        formPlaceholder,
        formButtonText,
        checkboxText,
        errorCheckbox,
        errorInput
      )
      break
    case "text":
      return heroText(heading, text, img)
      break

    case "service":
      return heroService(heading, colorHeading, services)
      break

    case "case":
      return heroCase(heading, text, img, logo)
      break

    default:
      break
  }
}

export default Hero
