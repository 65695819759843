import styled from "styled-components"
import HeroRectangle from "../../../static/hero_rectangle.svg"
import HeroPolygon from "../../../static/hero_polygon.svg"
import GeroEllipse from "../../../static/hero_ellipse.svg"

const HeroServicesStyled = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: flex-start;
  padding-left: 160px;
  position: relative;
  justify-content: space-between;
  @media (max-width: 1280px) {
    padding-left: 120px;
  }
  @media (max-width: 1080px) {
    padding-left: 0;
  }
  @media (max-width: 880px) {
    flex-direction: column;
    align-items: center;
  }
  &:before {
    content: "";
    left: 40px;
    position: absolute;
    height: 669px;
    width: 1px;
    background: #4e4d4d;
    @media (max-width: 1080px) {
      content: unset;
    }
  }
  .hero__services__item {
    max-width: 280px;
    display: flex;
    flex-direction: column;
    div[class*="ButtonStyles"] {
      margin-top: auto;
    }
    @media (max-width: 1280px) {
      max-width: 250px;
    }
    @media (max-width: 880px) {
      max-width: 220px;
      margin: 0 0 40px;
    }
    &--1 {
      .hero__services__item__number {
        color: #4c4ae5;
        position: relative;
        span {
          position: absolute;
          top: 50%;
          left: 7%;
          transform: translate(-50%, -50%);
          width: 141px;
          height: 141px;
          @media (max-width: 880px) {
            transform: translate(0%, -50%);
          }
          &:after {
            content: "";
            display: block;
            width: 141px;
            height: 141px;
            background: url(${GeroEllipse});
            background-size: contain;
            background-repeat: no-repeat;
            animation: slide-bck-center 2s linear infinite alternate-reverse
              both;
          }
        }
      }
    }
    &--2 {
      .hero__services__item__number {
        color: #d00060;
        position: relative;
        span {
          position: absolute;
          top: 84%;
          transform: translate(-31%, -70%);
          width: 144px;
          height: 144px;
          @media (max-width: 880px) {
            transform: translate(-39%, -70%);
          }
          &:after {
            content: "";
            display: block;
            width: 144px;
            height: 144px;
            background: url(${HeroRectangle});
            background-size: contain;
            background-repeat: no-repeat;
            animation: rotate-center 5s linear infinite both;
          }
        }
      }
      .hero__services__item__heading {
        max-width: 180px;
      }
    }
    &--3 {
      .hero__services__item__number {
        color: #eaa315;
        position: relative;
        span {
          top: 73%;
          transform: translate(-28%, -59%) scale(1.2);
          position: absolute;
          width: 144px;
          height: 144px;
          @media (max-width: 880px) {
            transform: translate(-33%, -70%) scale(1.1);
          }
          &:after {
            content: "";
            display: block;
            width: 133px;
            height: 137px;
            background: url(${HeroPolygon});
            background-size: contain;
            background-repeat: no-repeat;
            animation: rotate-center 5s linear infinite both;
          }
        }
      }
      .hero__services__item__heading {
        max-width: 220px;
      }
    }
    &__number {
      font-size: 40px;
      padding-bottom: 15px;
      font-weight: 500;
      @media (max-width: 880px) {
        text-align: center;
      }
    }
    &__heading {
      color: #fff;
      line-height: 27px;
      font-size: 21px;
      font-weight: 500;
      padding-bottom: 40px;
      height: 4.5em;
      margin-bottom: 0;
      margin-top: 0;
      z-index: 2;
      position: relative;
      @media (max-width: 880px) {
        text-align: center;
      }
    }
    &__description {
      line-height: 27px;
      font-size: 18px;
      color: #fff;
      margin-top: 0;
      z-index: 2;
      font-weight: 400;
      @media (max-width: 880px) {
        display: none;
      }
    }
    a {
      margin-top: 45px;
      font-weight: 500;
      color: #fff;
      &:hover {
        color: #000;
      }
      @media (max-width: 880px) {
        display: none;
      }
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes slide-bck-center {
    0% {
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`

export default HeroServicesStyled
