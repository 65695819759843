import styled from "styled-components"

const CaseStudyListStyled = styled.div`
  .caseStudyList {
    &__img {
    }
    &__item {
      height: 35vw;
      /* height: 480px; */
      overflow: hidden;
      position: relative;
      margin-bottom: 70px;
      @media (max-width: 1480px) {
        height: 500px;
      }
      @media (max-width: 1300px) {
        height: 440px;
      }
      .tmp {
        width: 50%;
        @media (max-width: 1080px) {
          width: 0;
        }
      }
      @media (max-width: 1080px) {
        display: flex;
        /* flex-wrap: wrap; */
      }
      @media (max-width: 880px) {
        margin-bottom: 0;
        height: initial;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 55px;
        &:last-of-type() {
          padding-bottom: 0;
        }
        .container {
          order: 2;
        }
      }
      &--odd {
        .caseStudyList__img {
          left: 0;
          @media (max-width: 880px) {
            left: initial;
          }
        }
        .container {
          justify-content: flex-end;
        }
        .caseStudyList__number {
          left: 60vw;
          @media (max-width: 880px) {
            left: initial;
          }
          &:after {
            right: 40px;
          }
        }
        .caseStudyList__img img {
          object-position: right;
          @media (max-width: 600px) {
            object-fit: contain;
            height: auto;
            width: 100%;
          }
        }
      }
      &--even {
        .caseStudyList__img {
          right: 0;
          @media (max-width: 880px) {
            right: initial;
            text-align: right;
          }
        }
        .container {
          justify-content: flex-start;
        }
        .caseStudyList__img img {
          @media (max-width: 880px) {
            object-position: right;
          }
          @media (max-width: 600px) {
            object-fit: contain;
            height: auto;
            width: 100%;
          }
        }
        .caseStudyList__number {
          right: 60vw;
          @media (max-width: 880px) {
            right: initial;
            text-align: center;
          }
          &:after {
            left: 40px;
          }
        }
      }
      &--showed {
        .caseStudyList__number {
          span {
            opacity: 1;
          }
          &:after {
            max-width: 30vw;
            @media (max-width: 1400px) {
              max-width: 23vw;
            }
          }
        }
      }
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        @media (max-width: 1080px) {
          height: auto;
        }
        /* max-width: 590px; */
      }
    }
    &__half {
      width: 540px;
      @media (max-width: 1280px) {
        width: 420px;
      }
      @media (max-width: 1080px) {
        width: 390px;
      }
      @media (max-width: 880px) {
        width: 100%;
      }
    }
    &__img {
      position: absolute;
      top: 0;
      bottom: 0;
      @media (max-width: 1080px) {
        position: relative !important;
      }
      @media (max-width: 880px) {
        position: relative !important;
        order: 1;
        width: 100%;
      }
      img {
        width: 47vw !important;
        height: 100%;
        /* object-fit: cover; */
        object-fit: contain;
        /* @media (max-width: 1480px) {
          object-fit: cover;
        } */
        @media (max-width: 1200px) {
          object-fit: cover;
        }
        @media (max-width: 1080px) {
          height: 100%;
          object-position: left;
        }
        @media (max-width: 880px) {
          width: calc(100% - 25px) !important;
        }
      }
      /* width: 100%; */
    }
    &__heading {
      font-size: 32px;
      line-height: 44px;
      text-align: center;
      font-weight: 500;
      @media (max-width: 1280px) {
        font-size: 27px;
        line-height: 37px;
      }
      @media (max-width: 1080px) {
        font-size: 24px;
        line-height: 32px;
      }
      @media (max-width: 880px) {
        font-size: 30px;
      }
      &--underline {
        &:after {
          content: "";
          margin: 20px auto;
          display: block;
          width: 30px;
          height: 1px;
          background: #707070;
        }
      }
    }
    &__description {
      font-size: 18px;
      line-height: 26px;
      font-weight: 300;
      text-align: center;
    }
    &__number {
      position: absolute;
      margin: 0;
      top: 30px;
      display: flex;
      align-items: center;
      @media (max-width: 880px) {
        position: relative;
        top: initial;
        justify-content: center;
        padding-top: 20px;
      }
      span {
        opacity: 0;
        transition: 0.3s all 0.4s;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        margin-top: 5px;
        width: 100vw;
        max-width: 0vw;
        height: 1px;
        background: #707070;
        z-index: 20;
        transition: 0.4s all linear;
        @media (max-width: 880px) {
          content: unset;
        }
      }
    }
  }
`

export default CaseStudyListStyled
